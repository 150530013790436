/* eslint-disable no-undef */
import { formatUrl } from '../../helpers/ApiClient';
import { CONSTANTS, ROUTE_OPTIONS, SIDE_MENU_CHANGE } from '../../constants';
import moment from 'moment';
import navigationConfig from '../../configs/navigationConfig';

export const getUserRoleId = () => {
  const roleId = window.localStorage.getItem('userData') && JSON.parse(
    window.localStorage.getItem('userData')
  ).roleId;

  return roleId;
};

// get logged in user Id
export const getAuthUser = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData') || {});
  const user = userData && userData.userDetail;
  return user;
};

// get logged in user Id
export const getAuthUserId = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData') || {});
  const userId = userData && userData.userDetail && userData.userDetail.id;
  return userId;
};

export const getAuthUserData = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData')) || {};
  return userData;
};

export const getDate = (dateObj) => {
  const dateFormat = dateObj && moment(dateObj.toString().substring(0, 10)).format('YYYY/MM/DD');
  const date = new Date(dateFormat);
  return date;
};

const download = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const fileExportFromStream = ({
  method = 'GET',
  path,
  params = {},
  body,
  fileName = 'export.txt',
  setLoader = () => {}
}) => {
  setLoader(true);
  const formattedUrl = formatUrl(path);
  const constructedUrl = new URL(formattedUrl);
  Object.keys(params).forEach((key) => constructedUrl.searchParams.append(key, params[key]));

  const fetchParams = {
    method,
    'Content-Type': 'application/json',
    headers: new Headers({
      Authorization: `Bearer ${JSON.parse(window.localStorage.getItem('userData')).access_token
        }`,
      'Content-Type': 'application/json',
    })
  };

  fetchParams.body = JSON.stringify(body);
  fetch(constructedUrl, { ...fetchParams }).then((res) => {
    res.blob().then((blob) => {
      download(blob, fileName);
      setLoader(false);
    });
  });
};

export const fcmNotifyClient = (body) => {
  fetch(CONSTANTS.fcmUrl, {
    method: 'POST',
    headers: {
      Authorization: CONSTANTS.fcmAuthorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
};

export const accesRightSetting = () => {
  const menuAccess = window.localStorage.getItem('userAccessRights')
    ? JSON.parse(window.localStorage.getItem('userAccessRights')).menus
    : [];

  const menuName = window.location.pathname.split('/')[1];
  const menuList = menuName === 'student' ? navigationConfig[1] : navigationConfig.find((e) => e.id === menuName);

  if (getUserRoleId() === 99 || getUserRoleId() === 100) {
    return { menuList: (menuList && menuList.children) || [] };
  }
  let sideMenuAccess = { menuList: [] };
  if (menuList && menuList.children && menuList.children.length > 0) {
    sideMenuAccess = {
      ...menuList,
      children: menuList && menuList.children && menuList.children.filter((e) => {
        return menuAccess[e.accessRight] > 0;
      })
    };
    return { menuList: sideMenuAccess.children || [] };
  }
  return sideMenuAccess;
};

export const arrayBufferToBase64 = (buffer) => {
  const enc = new TextDecoder('utf-8');
  const bytes = new Uint8Array(buffer);
  return enc.decode(bytes);
};

export const amountFormatter = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const formattedAmount = formatter.format(amount);
  return formattedAmount;
};

export const phoneNumberFormatter = (y) => {
  const x = y.toString().replaceAll(/[^0-9]+/g, '');
  const number = x && x.slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  const code = x && x.length > 10 ? `+${x.slice(0, x.length - 10).slice(-4)}` : '';
  return `${code} ${number}`;
};

export const getAuthUserSessionId = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData') || {});
  const userSessionId = userData && userData.userSessionId;
  return userSessionId;
};

export const changeSideMenu = (data, res) => {
  let menu = [];
  const accepted = res?.data?.status?.leadStatusId && res.data.status.leadStatusId > 5 && res.data.status.leadStatusId < 11;
  if (data.detailsAdded) {
    if (accepted) {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.BACKGROUND?.includes(data.name);
      });
    } else {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.BACKGROUND?.filter((x) => x !== 'acceptance')?.includes(data.name);
      });
    }
  }
  if (data.backgroundAdded) {
    if (accepted) {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.ACADEMIC?.includes(data.name);
      });
    } else {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.ACADEMIC?.filter((x) => x !== 'acceptance')?.includes(data.name);
      });
    }
  }
  if (data.academicsAdded) {
    if (accepted) {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.QUESTIONS?.includes(data.name);
      });
    } else {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.QUESTIONS?.filter((x) => x !== 'acceptance')?.includes(data.name);
      });
    }
  }
  if (data.additionalAdded) {
    if (accepted) {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.SUBMIT?.includes(data.name);
      });
    } else {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.SUBMIT?.filter((x) => x !== 'acceptance')?.includes(data.name);
      });
    }
  }
  if (data.submitAdded) {
    if (accepted) {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.TRANSCIPTS?.includes(data.name);
      });
    } else {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.TRANSCIPTS?.filter((x) => x !== 'acceptance')?.includes(data.name);
      });
    }
  }
  if (!data.detailsAdded) {
    if (accepted) {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.MYDETAILS?.includes(data.name);
      });
    } else {
      menu = ROUTE_OPTIONS.APPLICANT.PROFILE.children.filter((data) => {
        return SIDE_MENU_CHANGE.MYDETAILS?.filter((x) => x !== 'acceptance')?.includes(data.name);
      });
    }
  }
  return menu;
};
