/* eslint-disable import/no-named-as-default */
import { combineReducers } from 'redux';
import login from './login';
import passwordChange from './passwordChange';

const loginReducer = combineReducers({
  login,
  passwordChange
});

export default loginReducer;
