/* eslint-disable import/no-cycle */
import React from 'react';
import * as Icon from 'react-feather';
// import { ROUTE_OPTIONS } from '../constants';
// import { getMenuByAccess } from '../utility/helpers/accessRightHelper';
// import { getAuthUserData, getUserRoleId } from '../utility/helpers/helperFunction';

const navigationConfig = [
  {
    id: 'lead',
    title: 'Lead',
    type: 'item',
    adminView: true,
    icon: <Icon.Users size={40} />,
    navLink: '/lead/list',
    accessRight: '/lead',
    text: 'If your actions inspire others to dream more, learn more, do more and become more, you are a leader.'
  },
];

export default navigationConfig;
