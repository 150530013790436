/* eslint-disable */
import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap"
import * as Icon from "react-feather"
import { history } from "../../../history"
import { Link } from 'react-router-dom'
import { getAuthUserId, getAuthUserSessionId } from "../../../utility/helpers/helperFunction"
// import NotificationPayent from '../../../components/LandingPage/Header/NotiicationsPayment'
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import TodoTaskList from './todoTask';
// import Loader from '../../../components/@vuexy/spinner/Fallback-spinner'
// const handleNavigation = (e, path) => {
//   e.preventDefault()
//   history.push(path)
// }

const UserDropdown = props => {
  // const { logout, isAuthenticated } = useAuth0()
  const userId = getAuthUserId()
  const sessionId = getAuthUserSessionId();
  return (
    <DropdownMenu right>
      <DropdownItem
      tag="a"
      href="/settings/user/password"
        onClick={e => {
        
        history.push("/settings/user/password")
      }
      
      }
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle dropdownMenu">Change Password</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="/login"
        onClick={e => {
          e.preventDefault()
          window.localStorage.clear();
            history.push('/login');
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
      
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = { todoModalOpen: false }
  render() {
    return (
      <ul className='nav navbar-nav navbar-nav-user float-right d-flex align-items-center'>
         <UncontrolledDropdown
          tag='li'
          className='dropdown-notification nav-item'>
          <Link className='nav-link' to='/calendar'>
            <Icon.Calendar size={20} data-tour='calendar' id="calendarHeader" />
            <UncontrolledTooltip placement="top" target="calendarHeader">Calendar</UncontrolledTooltip>
          </Link>
        </UncontrolledDropdown>
        {/*<UncontrolledDropdown
          tag='li'
          className='dropdown-notification nav-item'>
          <a className='nav-link' target="_blank" href="https://mail.google.com/" rel="noopener noreferrer">
            <Icon.Mail size={20} id="sendMailHeader" data-tour='gmail' />
            <UncontrolledTooltip placement="top" target="sendMailHeader">Inbox/Email</UncontrolledTooltip>
          </a>
        </UncontrolledDropdown> */}
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item cursor-pointer"
        >
          <span type="button" className="nav-link" onClick={() => this.setState({ todoModalOpen: true })}>
            <Icon.CheckCircle size={20} data-tour="to-do" id="todoTaskList" />
            <UncontrolledTooltip placement="top" target="todoTaskList">Todo Task</UncontrolledTooltip>
          </span>
        </UncontrolledDropdown>
        <UncontrolledDropdown
          tag='li'
          className='dropdown-user nav-item d-flex'>
          <DropdownToggle tag='a' className='nav-link dropdown-user-link'>
            <div className='user-nav d-sm-flex d-none'>
              <span className='user-name text-bold-600 m-0'>
                {this.props.userName}
              </span>
            </div>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
        <TodoTaskList todoModalOpen={this.state.todoModalOpen} setTodoModalOpen={(val) => this.setState({ todoModalOpen: val })} />
      </ul>
    )
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  // postUserLogout
},dispatch)

export default connect(null,mapDispatchToProps)(NavbarUser)
