import { combineReducers } from 'redux';
import applicant from './application';
import sideMenuChange from './sideMenuChange';

const applicationReducer = combineReducers({
  applicant,
  sideMenuChange
});

export default applicationReducer;
