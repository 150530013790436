import { combineReducers } from 'redux';
import customizer from './customizer';
import auth from './auth';
import dataList from './data-list';
import application from './application';
import oauth from './oauth';
import settingsReducer from './settings';
import calendar from './calendar';
import todoApp from './todo';

const rootReducer = combineReducers({
  calendar,
  customizer,
  auth,
  dataList,
  application,
  oauth,
  settingsReducer,
  todoApp,
});

export default rootReducer;
