import React from 'react';
import * as Icon from 'react-feather';

const horizontalMenuConfig = [
  {
    id: 'medical',
    title: 'Medical',
    type: 'collapse',
    icon: <Icon.Droplet size={20} />,
    children: [
      {
        id: 'student',
        title: 'Student',
        type: 'item',
        icon: <Icon.User size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/student/list'
      },
      {
        id: 'clinicals',
        title: 'Clinicals',
        type: 'item',
        icon: <Icon.Thermometer size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/medical/clinicals/assign-rotation/list'
      },
      {
        id: 'attendance',
        title: 'Attendance',
        type: 'item',
        icon: <Icon.Clipboard size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/medical/attendance'
      },
      {
        id: 'request',
        title: 'Request',
        type: 'item',
        icon: <Icon.UserPlus size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/medical/request/clinical-request'
      },
      {
        id: 'alumniList',
        title: 'AlumniList',
        type: 'item',
        icon: <Icon.Users size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/medical/alumniList'
      },
      {
        id: 'register',
        title: 'Register',
        type: 'item',
        icon: <Icon.UserPlus size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/medical/register'
      }
    ]
  },
  {
    id: 'accounting',
    title: 'Accounting',
    type: 'collapse',
    icon: <Icon.Layers size={20} />,
    children: [
      {
        id: 'invoices',
        title: 'Invoices',
        type: 'item',
        icon: <Icon.List size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/accounting/invoices'
      },
      {
        id: 'scholarship',
        title: 'Scholarship',
        type: 'item',
        icon: <Icon.BookOpen size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/accounting/scholarship'
      },
      {
        id: 'loa',
        title: 'LOA',
        type: 'item',
        icon: <Icon.ArrowDown size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/accounting/loa'
      },
      {
        id: 'revenue',
        title: 'Revenue',
        type: 'item',
        icon: <Icon.DollarSign size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/accounting/revenue'
      },
      // {
      //   id: "autoPaymentList",
      //   title: "AutoPayment List",
      //   type: "item",
      //   icon: <Icon.List size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/accounting/autoPaymentList"
      // },
      // {
      //   id: "userAccess",
      //   title: "User Access",
      //   type: "item",
      //   icon: <Icon.UserCheck size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/accounting/userAccess"
      // },
      {
        id: 'lead',
        title: 'Lead',
        type: 'item',
        icon: <Icon.Users size={12} />,
        permissions: ['admin', 'editor'],
        navLink: '/accounting/lead'
      },
    ]
  },
  {
    id: 'employee',
    title: 'Employee',
    type: 'item',
    icon: <Icon.Users size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/employee/list'
  },
  {
    id: 'lead',
    title: 'Lead',
    type: 'item',
    icon: <Icon.Users size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/lead/list'
  },
  {
    id: 'task',
    title: 'Task',
    type: 'item',
    icon: <Icon.List size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/task/list'
  },
  {
    id: 'exams',
    title: 'Exams',
    type: 'item',
    icon: <Icon.Clipboard size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/exams/list'
  },
  {
    id: 'moreOptions',
    title: 'More',
    type: 'collapse',
    icon: <Icon.Layers size={20} />,
    children: [
      {
        id: 'mailLog',
        title: 'Mail Log',
        type: 'item',
        icon: <Icon.Mail size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/mailLog/incoming'
      },
      {
        id: 'document',
        title: 'Document',
        type: 'item',
        icon: <Icon.File size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/document/list'
      },
      {
        id: 'reports',
        title: 'Reports',
        type: 'item',
        icon: <Icon.FileText size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/reports/list'
      },
      {
        id: 'admin',
        title: 'System Admin',
        type: 'item',
        icon: <Icon.User size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/admin/user'
      },
      {
        id: 'task',
        title: 'Task',
        type: 'item',
        icon: <Icon.List size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/task/list'
      },
      {
        id: 'turkey',
        title: 'Turkey',
        type: 'item',
        icon: <Icon.Globe size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/turkey/list'
      },
    ]
  }
];

export default horizontalMenuConfig;
