export const GET_TASK_LIST_REQUESTED = 'task/GET_TASK_LIST_REQUESTED';
export const GET_TASK_LIST_SUCCESS = 'task/GET_TASK_LIST_SUCCESS';
export const GET_TASK_LIST_FAILURE = 'task/GET_TASK_LIST_FAILURE';

export const GET_TASK_DROPDOWN_ASSIGNED = 'task/GET_TASK_DROPDOWN_ASSIGNED';

export const GET_TASK_DROPDOWN_ASSIGNED_TO = 'task/GET_TASK_DROPDOWN_ASSIGNED_TO';
export const ADD_TASK_LIST_SUCCESS = 'task/ADD_TASK_LIST_SUCCESS';
export const GET_TASK_BY_ID_SUCCESS = 'task/GET_TASK_BY_ID_SUCCESS';
export const UPDATE_TASK_SUCCESS = 'task/UPDATE_TASK_SUCCESS';
export const GET_TASK_SUCCESS = 'task/GET_TASK_SUCCESS';

export const ADD_TODO_REQUESTED = 'todo/ADD_TODO_REQUESTED';
export const ADD_TODO_SUCCESS = 'todo/ADD_TODO_SUCCESS';
export const ADD_TODO_FAILURE = 'todo/ADD_TODO_FALURE';
export const GET_TODO_LIST_BYWEEK_SUCCESS = 'todo/GET_TODO_LIST_BYWEEK_SUCCESS';
export const UPDATE_TODO_SUCCESS = 'todo/UPDATE_TODO_SUCCESS';
export const GET_TODO_LIST_SUCCESS = 'todo/GET_TODO_LIST_SUCCESS';
export const GET_TODO_LIST_ALL_DATA_SUCCESS = 'todo/GET_TODO_LIST_ALL_DATA_SUCCESS';

const initialState = {
  loading: false,
  error: false,
  taskList: null,
  taskAssigned: null,
  taskAssignedTo: null,
  taskById: null,
  taskByTaskId: null,
  todoList: null,
  todoListAllData: null,
  todoListByWeek: null
};

const todo = (state = initialState, action) => {
  switch (action.type) {
  case GET_TASK_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_TASK_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskList: action.result
    };
  }
  case GET_TASK_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_TASK_DROPDOWN_ASSIGNED: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskAssigned: action && action.result
    };
  }
  case GET_TASK_DROPDOWN_ASSIGNED_TO: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskAssignedTo: action && action.result
    };
  }
  case ADD_TASK_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  case GET_TASK_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskById: action.result
    };
  }
  case UPDATE_TASK_SUCCESS: {
    return {
      loading: false,
      loaded: true,
      error: false
    };
  }
  case GET_TASK_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      taskByTaskId: action.result
    };
  }
  case ADD_TODO_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case ADD_TODO_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_TODO_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true,
    };
  }
  case GET_TODO_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      todoList: action.result
    };
  }
  case GET_TODO_LIST_ALL_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      todoListAllData: action.result
    };
  }
  case GET_TODO_LIST_BYWEEK_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      todoListByWeek: action.result
    };
  }
  case UPDATE_TODO_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false
    };
  }
  default:
    return state;
  }
};

export const addTodo = (data) => {
  return {
    types: [ADD_TODO_REQUESTED, ADD_TODO_SUCCESS, ADD_TODO_FAILURE],
    promise: (client) => client.post('addTodo', { data })
  };
};
export const getTodoList = (params) => {
  return {
    types: [ADD_TODO_REQUESTED, GET_TODO_LIST_SUCCESS, ADD_TODO_FAILURE],
    promise: (client) => client.get('getTodoList', { params })
  };
};
export const getTodoListByWeek = (params) => {
  return {
    types: [ADD_TODO_REQUESTED, GET_TODO_LIST_BYWEEK_SUCCESS, ADD_TODO_FAILURE],
    promise: (client) => client.get('getTodoListByWeek', { params })
  };
};
export const updateToDoSatatus = (data) => {
  return {
    types: [ADD_TODO_REQUESTED, UPDATE_TODO_SUCCESS, ADD_TODO_FAILURE],
    promise: (client) => client.put('updateSatatus', { data })
  };
};

export default todo;
