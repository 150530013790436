export const ADD_OR_UPDATE_REQUESTED = 'settings/ADD_OR_UPDATE_REQUESTED';
export const ADD_OR_UPDATE_SUCCESS = 'settings/ADD_OR_UPDATE_SUCCESS';
export const ADD_OR_UPDATE_FAILURE = 'settings/ADD_OR_UPDATE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  userProfile: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_OR_UPDATE_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case ADD_OR_UPDATE_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
    };
  }
  case ADD_OR_UPDATE_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: true
    };
  }
  default:
    return state;
  }
};

export const changeUserPassword = (data) => {
  return {
    types: [ADD_OR_UPDATE_REQUESTED, ADD_OR_UPDATE_SUCCESS, ADD_OR_UPDATE_FAILURE],
    promise: (client) => client.post('changeUserPassword', { data })
  };
};
