export const ADD_APPLICATION_REQUESTED = 'application/ADD_APPLICATION_REQUESTED';
export const ADD_APPLICATION_SUCCESS = 'application/ADD_APPLICATION_SUCCESS';
export const ADD_APPLICATION_FAILURE = 'application/ADD_APPLICATION_FAILURE';

export const COUNTRY_REQUESTED = 'applicant/COUNTRY_REQUESTED';
export const COUNTRY_SUCCESS = 'applicant/COUNTRY_SUCCESS';
export const COUNTRY_FAILURE = 'applicant/COUNTRY_FAILURE';

export const PROGRAM_REQUESTED = 'applicant/PROGRAM_REQUESTED';
export const PROGRAM_SUCCESS = 'applicant/PROGRAM_SUCCESS';
export const PROGRAM_FAILURE = 'applicant/PROGRAM_FAILURE';

export const SEMESTER_REQUESTED = 'applicant/SEMESTER_REQUESTED';
export const SEMESTER_SUCCESS = 'applicant/SEMESTER_SUCCESS';
export const SEMESTER_FAILURE = 'applicant/SEMESTER_FAILURE';

export const GET_APPLICANT_DETAILS_REQUESTED = 'applicant/GET_APPLICANT_DETAILS_REQUESTED';
export const GET_APPLICANT_DETAILS_SUCCESS = 'applicant/GET_APPLICANT_DETAILS_SUCCESS';
export const GET_APPLICANT_DETAILS_FAILURE = 'applicant/GET_APPLICANT_DETAILS_FAILURE';

export const GET_SEMESTER_DETAILS_REQUESTED = 'applicant/GET_SEMESTER_DETAILS_REQUESTED';
export const GET_SEMESTER_DETAILS_SUCCESS = 'applicant/GET_SEMESTER_DETAILS_SUCCESS';
export const GET_SEMESTER_DETAILS_FAILURE = 'applicant/GET_SEMESTER_DETAILS_FAILURE';

export const GET_SEMESTER_YEAR_DETAILS_REQUESTED = 'applicant/GET_SEMESTER_YEAR_DETAILS_REQUESTED';
export const GET_SEMESTER_YEAR_DETAILS_SUCCESS = 'applicant/GET_SEMESTER_YEAR_DETAILS_SUCCESS';
export const GET_SEMESTER_YEAR_DETAILS_FAILURE = 'applicant/GET_SEMESTER_YEAR_DETAILS_FAILURE';

export const UPDATE_BACKGROUND_DETAILS_REQUESTED = 'applicant/UPDATE_BACKGROUND_DETAILS_REQUESTED';
export const UPDATE_BACKGROUND_DETAILS_SUCCESS = 'applicant/UPDATE_BACKGROUND_DETAILS_SUCCESS';
export const UPDATE_BACKGROUND_DETAILS_FAILURE = 'applicant/UPDATE_BACKGROUND_DETAILS_FAILURE';

export const GET_BACKGROUND_DETAILS_REQUESTED = 'applicant/GET_BACKGROUND_DETAILS_REQUESTED';
export const GET_BACKGROUND_DETAILS_SUCCESS = 'applicant/GET_BACKGROUND_DETAILS_SUCCESS';
export const GET_BACKGROUND_DETAILS_FAILURE = 'applicant/GET_BACKGROUND_DETAILS_FAILURE';

export const GET_ACADEMIC_DETAILS_LIST_REQUESTED = 'applicant/GET_ACADEMIC_DETAILS_LIST_REQUESTED';
export const GET_ACADEMIC_DETAILS_LIST_SUCCESS = 'applicant/GET_ACADEMIC_DETAILS_LIST_SUCCESS';
export const GET_ACADEMIC_DETAILS_LIST_FAILURE = 'applicant/GET_ACADEMIC_DETAILS_LIST_FAILURE';

export const GET_MONTH_LIST_REQUESTED = 'applicant/GET_MONTH_LIST_REQUESTED';
export const GET_MONTH_LIST_SUCCESS = 'applicant/GET_MONTH_LIST_SUCCESS';
export const GET_MONTH_LIST_FAILURE = 'applicant/GET_MONTH_LIST_FAILURE';

export const GET_TEST_LIST_REQUESTED = 'applicant/GET_TEST_LIST_REQUESTED';
export const GET_TEST_LIST_SUCCESS = 'applicant/GET_TEST_LIST_SUCCESS';
export const GET_TEST_LIST_FAILURE = 'applicant/GET_TEST_LIST_FAILURE';

export const GET_ADDITIONAL_QUESTIONS_REQUESTED = 'applicant/GET_ADDITIONAL_QUESTIONS_REQUESTED';
export const GET_ADDITIONAL_QUESTIONS_SUCCESS = 'applicant/GET_ADDITIONAL_QUESTIONS_SUCCESS';
export const GET_ADDITIONAL_QUESTIONS_FAILURE = 'applicant/GET_ADDITIONAL_QUESTIONS_FAILURE';

export const GET_DOCUMENT_LIST_REQUESTED = 'applicant/GET_DOCUMENT_LIST_REQUESTED';
export const GET_DOCUMENT_LIST_SUCCESS = 'applicant/GET_DOCUMENT_LIST_SUCCESS';
export const GET_DOCUMENT_LIST_FAILURE = 'applicant/GET_DOCUMENT_LIST_FAILURE';

export const GET_DOC_BY_ID_REQUESTED = 'applicant/GET_DOC_BY_ID_REQUESTED';
export const GET_DOC_BY_ID_SUCCESS = 'applicant/GET_DOC_BY_ID_SUCCESS';
export const GET_DOC_BY_ID_FAILURE = 'applicant/GET_DOC_BY_ID_FAILURE';

export const STATE_CURRENT_LIST_REQUESTED = 'lead/STATE_CURRENT_LIST_REQUESTED';
export const STATE_CURRENT_LIST_SUCCESS = 'lead/STATE_CURRENT_LIST_SUCCESS';
export const STATE_CURRENT_LIST_FAILURE = 'lead/STATE_CURRENT_LIST_FAILURE';

export const STATE_LIST_REQUESTED = 'lead/STATE_LIST_REQUESTED';
export const STATE_LIST_SUCCESS = 'lead/STATE_LIST_SUCCESS';
export const STATE_LIST_FAILURE = 'lead/STATE_LIST_FAILURE';

export const GET_APPLICATION_SUBMITTED_DETAILS_REQUESTED = 'applicant/GET_APPLICATION_SUBMITTED_DETAILS_REQUESTED';
export const GET_APPLICATION_SUBMITTED_DETAILS_SUCCESS = 'applicant/GET_APPLICATION_SUBMITTED_DETAILS_SUCCESS';
export const GET_APPLICATION_SUBMITTED_DETAILS_FAILURE = 'applicant/GET_APPLICATION_SUBMITTED_DETAILS_FAILURE';

export const GET_SIDE_MENU_REQUESTED = 'applicant/GET_SIDE_MENU_REQUESTED';
export const GET_SIDE_MENU_SUCCESS = 'applicant/GET_SIDE_MENU_SUCCESS';
export const GET_SIDE_MENU_FAILURE = 'applicant/GET_SIDE_MENU_FAILURE';

export const GET_ACCEPTANCE_DATA_REQUESTED = 'lead/GET_ACCEPTANCE_DATA_REQUESTED';
export const GET_ACCEPTANCE_DATA_SUCCESS = 'lead/GET_ACCEPTANCE_DATA_SUCCESS';
export const GET_ACCEPTANCE_DATA_FAILURE = 'lead/GET_ACCEPTANCE_DATA_FAILURE';

export const GET_APPLICANT_SEM_LIST_REQUESTED = 'lead/GET_APPLICANT_SEM_LIST_REQUESTED';
export const GET_APPLICANT_SEM_LIST_SUCCESS = 'lead/GET_APPLICANT_SEM_LIST_SUCCESS';
export const GET_APPLICANT_SEM_LIST_FAILURE = 'lead/GET_APPLICANT_SEM_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  profileLoading: false,
  countries: null,
  program: null,
  semester: null,
  getApplicantDetail: null,
  getSemesterDetails: null,
  getSemesterYearDetails: null,
  getBackgroundDetails: null,
  getAcademicsList: null,
  monthsList: null,
  testList: null,
  academicDetails: null,
  docList: null,
  docById: null,
  applicationSubmittedDetails: null,
  stateList: null,
  currentStateList: null,
  sideMenuData: null,
  getAcceptanceLetter: null,
  getApplicantCTSemList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_APPLICATION_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case ADD_APPLICATION_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case ADD_APPLICATION_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true
    };
  }
  case COUNTRY_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case COUNTRY_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      countries: action.result?.data,
    };
  }
  case COUNTRY_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      countries: null,
    };
  }
  case PROGRAM_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case PROGRAM_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      program: action.result?.data,
    };
  }
  case PROGRAM_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      program: null,
    };
  }
  case SEMESTER_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case SEMESTER_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      semester: action.result?.data,
    };
  }
  case SEMESTER_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      semester: null,
    };
  }
  case GET_APPLICANT_DETAILS_REQUESTED: {
    return {
      ...state,
      profileLoading: true,
      loaded: false
    };
  }
  case GET_APPLICANT_DETAILS_SUCCESS: {
    return {
      ...state,
      profileLoading: false,
      error: false,
      loaded: true,
      getApplicantDetail: action.result?.data,
    };
  }
  case GET_APPLICANT_DETAILS_FAILURE: {
    return {
      ...state,
      profileLoading: false,
      error: false,
      loaded: true,
      getApplicantDetail: null,
    };
  }
  case GET_SEMESTER_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_SEMESTER_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getSemesterDetails: action.result?.data,
    };
  }
  case GET_SEMESTER_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getSemesterDetails: null,
    };
  }
  case GET_SEMESTER_YEAR_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_SEMESTER_YEAR_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getSemesterYearDetails: action.result?.data,
    };
  }
  case GET_SEMESTER_YEAR_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getSemesterYearDetails: null,
    };
  }
  case GET_BACKGROUND_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_BACKGROUND_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getBackgroundDetails: action.result?.data,
    };
  }
  case GET_BACKGROUND_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getBackgroundDetails: null,
    };
  }
  case GET_ACADEMIC_DETAILS_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_ACADEMIC_DETAILS_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getAcademicsList: action.result?.data,
    };
  }
  case GET_ACADEMIC_DETAILS_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      getAcademicsList: null,
    };
  }
  case GET_MONTH_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_MONTH_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      monthsList: action.result?.data,
    };
  }
  case GET_MONTH_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      monthsList: null,
    };
  }
  case GET_TEST_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_TEST_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      testList: action.result?.data,
    };
  }
  case GET_TEST_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      testList: null,
    };
  }
  case GET_ADDITIONAL_QUESTIONS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_ADDITIONAL_QUESTIONS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      academicDetails: action.result?.data,
    };
  }
  case GET_ADDITIONAL_QUESTIONS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      academicDetails: null,
    };
  }
  case GET_DOCUMENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_DOCUMENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      docList: action.result?.data,
    };
  }
  case GET_DOCUMENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      docList: null,
    };
  }
  case GET_DOC_BY_ID_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_DOC_BY_ID_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      docById: action.result?.data,
    };
  }
  case GET_DOC_BY_ID_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      docById: null,
    };
  }
  case GET_APPLICATION_SUBMITTED_DETAILS_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_APPLICATION_SUBMITTED_DETAILS_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      applicationSubmittedDetails: action.result?.data,
    };
  }
  case GET_APPLICATION_SUBMITTED_DETAILS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      applicationSubmittedDetails: null,
    };
  }
  case STATE_CURRENT_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case STATE_CURRENT_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      currentStateList: action.result?.data,
    };
  }
  case STATE_CURRENT_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      currentStateList: null,
    };
  }
  case STATE_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case STATE_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      stateList: action.result?.data,
    };
  }
  case STATE_LIST_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      stateList: null,
    };
  }
  case GET_SIDE_MENU_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case GET_SIDE_MENU_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      sideMenuData: action.result?.data,
    };
  }
  case GET_SIDE_MENU_FAILURE: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      stateList: null,
    };
  }
  case GET_ACCEPTANCE_DATA_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false
    };
  }
  case GET_ACCEPTANCE_DATA_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getAcceptanceLetter: action.result
    };
  }
  case GET_ACCEPTANCE_DATA_FAILURE: {
    return {
      ...state,
      loading: false,
      loaded: false,
      error: true,
      getAcceptanceLetter: null,
    };
  }
  case GET_APPLICANT_SEM_LIST_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
    };
  }
  case GET_APPLICANT_SEM_LIST_SUCCESS: {
    return {
      ...state,
      loading: false,
      loaded: true,
      error: false,
      getApplicantCTSemList: action.result?.data
    };
  }
  case GET_APPLICANT_SEM_LIST_FAILURE: {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: false,
      getApplicantCTSemList: null
    };
  }

  default:
    return state;
  }
};

export const getCountries = () => {
  return {
    types: [COUNTRY_REQUESTED, COUNTRY_SUCCESS, COUNTRY_FAILURE],
    promise: (client) => client.get('countries')
  };
};

export const signUp = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post('signUp', { data })
  };
};

export const getProgram = () => {
  return {
    types: [PROGRAM_REQUESTED, PROGRAM_SUCCESS, PROGRAM_FAILURE],
    promise: (client) => client.get('getProgram')
  };
};

export const getSemester = (startYear, endYear) => {
  return {
    types: [SEMESTER_REQUESTED, SEMESTER_SUCCESS, SEMESTER_FAILURE],
    promise: (client) => client.get(`getSemester/${startYear}/${endYear}`)
  };
};

export const getApplicantDetails = (id) => {
  return {
    types: [GET_APPLICANT_DETAILS_REQUESTED, GET_APPLICANT_DETAILS_SUCCESS, GET_APPLICANT_DETAILS_FAILURE],
    promise: (client) => client.get(`getApplicantDetails/${id}`)
  };
};

export const updateBackgroundDetails = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post('updateBackground', { data, isMultiPart: true })
  };
};

export const getSemesterByCourse = (id) => {
  return {
    types: [GET_SEMESTER_DETAILS_REQUESTED, GET_SEMESTER_DETAILS_SUCCESS, GET_SEMESTER_DETAILS_FAILURE],
    promise: (client) => client.get(`semesterByCourse/${id}`)
  };
};

export const getSemesterYear = (data) => {
  return {
    types: [GET_SEMESTER_YEAR_DETAILS_REQUESTED, GET_SEMESTER_YEAR_DETAILS_SUCCESS, GET_SEMESTER_YEAR_DETAILS_FAILURE],
    promise: (client) => client.get(`getSemesterYear/${data.startYear}/${data.endYear}`)
  };
};

export const getBackgroundDetails = (id) => {
  return {
    types: [GET_BACKGROUND_DETAILS_REQUESTED, GET_BACKGROUND_DETAILS_SUCCESS, GET_BACKGROUND_DETAILS_FAILURE],
    promise: (client) => client.get(`getBackgroundDetails/${id}`)
  };
};

export const saveAcademicDetails = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post('saveAcademicDetails', { data, isMultiPart: true })
  };
};

export const saveAdditionalQuestions = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post('saveAdditionalQuestions', { data })
  };
};

export const updateProfile = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post('updateProfile', { data })
  };
};

export const getAcademicDetailsList = (id) => {
  return {
    types: [GET_ACADEMIC_DETAILS_LIST_REQUESTED, GET_ACADEMIC_DETAILS_LIST_SUCCESS, GET_ACADEMIC_DETAILS_LIST_FAILURE],
    promise: (client) => client.get(`getAcademicDetailsList/${id}`)
  };
};

export const getMonths = () => {
  return {
    types: [GET_MONTH_LIST_REQUESTED, GET_MONTH_LIST_SUCCESS, GET_MONTH_LIST_FAILURE],
    promise: (client) => client.get('getMonths')
  };
};

export const getTestDetails = () => {
  return {
    types: [GET_TEST_LIST_REQUESTED, GET_TEST_LIST_SUCCESS, GET_TEST_LIST_FAILURE],
    promise: (client) => client.get('getTestDetails')
  };
};

export const getAdditionalQuestions = (id) => {
  return {
    types: [GET_ADDITIONAL_QUESTIONS_REQUESTED, GET_ADDITIONAL_QUESTIONS_SUCCESS, GET_ADDITIONAL_QUESTIONS_FAILURE],
    promise: (client) => client.get(`getAdditionalQuestions/${id}`)
  };
};

export const saveDocument = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post('saveDocument', { data, isMultiPart: true })
  };
};

export const updateDocument = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post('updateDocument', { data, isMultiPart: true })
  };
};

export const getDocumentList = (data) => {
  return {
    types: [GET_DOCUMENT_LIST_REQUESTED, GET_DOCUMENT_LIST_SUCCESS, GET_DOCUMENT_LIST_FAILURE],
    promise: (client) => client.get(`getDocumentList/${data.id}/${data.isActive}/${data.categoryId}`)
  };
};

export const documentDownload = (id) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.get(`documentDownload/${id}`)
  };
};

export const getDocumentById = (id) => {
  return {
    types: [GET_DOC_BY_ID_REQUESTED, GET_DOC_BY_ID_SUCCESS, GET_DOC_BY_ID_FAILURE],
    promise: (client) => client.get(`getDocumentById/${id}`)
  };
};

export const documentDelete = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post(`deleteDocument/?docId=${data.docId}`)
  };
};

export const submitApplication = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post('submitApplication', { data })
  };
};

export const getApplicationSubmittedData = (id) => {
  return {
    types: [GET_APPLICATION_SUBMITTED_DETAILS_REQUESTED, GET_APPLICATION_SUBMITTED_DETAILS_SUCCESS, GET_APPLICATION_SUBMITTED_DETAILS_FAILURE],
    promise: (client) => client.get(`getApplicationSubmittedData/${id}`)
  };
};

export const getCurrentStateList = (countryId) => {
  return {
    types: [STATE_CURRENT_LIST_REQUESTED, STATE_CURRENT_LIST_SUCCESS, STATE_CURRENT_LIST_FAILURE],
    promise: (client) => client.get(`stateList/${countryId}`)
  };
};

export const getPermanentStateList = (countryId) => {
  return {
    types: [STATE_LIST_REQUESTED, STATE_LIST_SUCCESS, STATE_LIST_FAILURE],
    promise: (client) => client.get(`stateList/${countryId}`)
  };
};

export const profileUpload = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post('uploadProfilePic', { data, isMultiPart: true })
  };
};

export const sideMenuChange = (id) => {
  return {
    types: [GET_SIDE_MENU_REQUESTED, GET_SIDE_MENU_SUCCESS, GET_SIDE_MENU_FAILURE],
    promise: (client) => client.get(`sideMenuChange/${id}`)
  };
};

export const getAcceptanceLetter = (leadId) => {
  return {
    types: [GET_ACCEPTANCE_DATA_REQUESTED, GET_ACCEPTANCE_DATA_SUCCESS, GET_ACCEPTANCE_DATA_FAILURE],
    promise: (client) => client.get(`acceptanceFilePreview/${leadId}`)
  };
};

export const leadUploadImage = (data) => {
  return {
    types: [ADD_APPLICATION_REQUESTED, ADD_APPLICATION_FAILURE, ADD_APPLICATION_FAILURE],
    promise: (client) => client.post('leadUploadImage', { data, isMultiPart: true })
  };
};

export const getApplicantSemester = (params) => {
  return {
    types: [GET_APPLICANT_SEM_LIST_REQUESTED, GET_APPLICANT_SEM_LIST_SUCCESS, GET_APPLICANT_SEM_LIST_FAILURE],
    promise: (client) => client.get('getApplicantSemester', { params })
  };
};
