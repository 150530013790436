export default {
  STATUS: [
    {
      id: 1, name: 'Active', value: true, data: 1
    },
    {
      id: 0, name: 'Inactive', value: false, data: 0
    }
  ],
  GENDER: [
    {
      value: 'm', label: 'Male'
    },
    {
      value: 'f', label: 'Female'
    }
  ]
};
