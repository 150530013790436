export default {
  // The URL we're connecting to
  hostname: process.env.REACT_APP_API_SERVER_HOST || 'https://sms.xusom.com/xusomauth',
  xusomlead: process.env.REACT_APP_LEAD_API_HOST || 'https://sms.xusom.com',
  applicant: process.env.REACT_APP_LEAD_API_HOST || 'https://sms.xusom.com/xusomlead',
  xusomadmin: process.env.REACT_APP_EMPLOYEE_API_HOST || 'https://sms.xusom.com',
  // Map shortnames to the actual endpoints, so that we can use them in the middleware

  endpoints: {
    auth: '/account/login',
    resetPassword: '/xusomlead/account/resetpassword',
    changeUserPassword: '/applicant/account/change/password',
    countries: '/xusomlead/account/country',
    forgotPassword: '/xusomlead/account/forgotpassword',
    signUp: '/xusomlead/account/register',
    getProgram: '/xusomlead/account/program',
    getSemester: '/xusomlead/account/semesterYear',
    getApplicantDetails: '/applicant/account/get',
    updateBackground: '/applicant/account/leadbackground/immigration/save',
    getBackgroundDetails: '/applicant/account/leadbackground/immigration',
    getSemesterYear: '/xusomlead/account/numbers',
    semesterByCourse: '/xusomlead/account/semesterbycourse',
    saveAcademicDetails: '/applicant/account/new/academics/save',
    getAcademicDetailsList: '/applicant/account/new/academics',
    saveAdditionalQuestions: '/applicant/account/additionalquestion/save',
    getAdditionalQuestions: '/applicant/account/additionalquestion',
    updateProfile: '/applicant/account/update/applicant/profile',
    getMonths: '/applicant/account/months',
    getTestDetails: '/applicant/account/leadtest',
    saveDocument: '/applicant/account/upload',
    updateDocument: '/applicant/account/update',
    getDocumentList: '/applicant/account/docList',
    documentDownload: '/applicant/account/save',
    getDocumentById: '/applicant/account/get/document',
    deleteDocument: '/applicant/account/delete',
    submitApplication: '/applicant/account/submitapplication',
    getApplicationSubmittedData: '/applicant/account/submitapplication',
    stateList: '/xusomlead/utility/state',
    uploadProfilePic: '/applicant/account/upload/profilepic',
    sideMenuChange: '/applicant/account/page/authorise',
    acceptanceFilePreview: '/xusomlead/document/acceptanceletter/download',
    addTodo: '/xusomadmin/todo/add',
    getTodoList: '/xusomadmin/todo/list',
    getTodoListByWeek: '/xusomadmin/todo/list/currentWeek',
    updateSatatus: '/xusomadmin/todo/update-status',
    todoExportToExcel: '/xusomadmin/todo/todo-export',
    getCalendarEventsList: '/applicant/account/calendar/eventlist',
    addOrUpdateEvent: '/applicant/account/calendar/save',
    deletelCaledarEvent: '/applicant/account/calendar/delete',
    leadUploadImage: '/xusomlead/document/upload',
    getApplicantSemester: '/xusomlead/account/applicant/semesterYear/list',
  }
};
