export const PASSWORD_REQUESTED = 'passwordChange/PASSWORD_REQUESTED';
export const PASSWORD_SUCCESS = 'passwordChange/PASSWORD_SUCCESS';
export const PASSWORD_FAILURE = 'passwordChange/PASSWORD_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case PASSWORD_REQUESTED: {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }
  case PASSWORD_SUCCESS: {
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
    };
  }
  case PASSWORD_FAILURE: {
    return {
      ...state,
      loading: false,
      error: true,
      loaded: true
    };
  }
  default:
    return state;
  }
};

export const forcePasswordChange = (data) => {
  return {
    types: [PASSWORD_REQUESTED, PASSWORD_SUCCESS, PASSWORD_FAILURE],
    promise: (client) => client.post('forcePasswordChange', { data })
  };
};

export const resetPassword = (data) => {
  return {
    types: [PASSWORD_REQUESTED, PASSWORD_SUCCESS, PASSWORD_FAILURE],
    promise: (client) => client.post('resetPassword', { data })
  };
};

export const forgotPassword = (data, isApplicant) => {
  return {
    types: [PASSWORD_REQUESTED, PASSWORD_SUCCESS, PASSWORD_FAILURE],
    promise: (client) => client.get(`forgotPassword/${data}/${isApplicant}`)
  };
};
