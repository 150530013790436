export default {
  // Regex
  zipCode: /^[0-9]|(\d+[A-Za-z]+)|([A-Za-z]+\d+)|\w-\w+$/,
  // phoneValidation: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
  // phoneValidation: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
  phoneValidation: /^\D*(?:\d\D*){10,}$/,
  // phoneValidation: /^(\+?\d{0,4})?(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
  phoneValidationNew: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{5}\)?)?$/,
  passwordRegex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
  passportValidation: /^[a-zA-Z0-9]+$/,
  paymentWithDecimalValidation: /^(\d+)(\.\d{1,2})?$/,
  startEndWhiteSpaceValidation: /^[^\s]+(\s+[^\s]+)*$/,

  gpaWithDecimalValidation: /^$|^[0-9]{1,2}(\.[0-9]{1,2})?$/,
  UNOFFICIAL_TRANSCRIPTS: '690E6218-42DC-4AAA-98AE-9CA9C22364A8',
  LOA: 'CCBB0293-685B-4C58-92C6-027E6AE8F026',
  STATEMENT: '8EB80FD6-9AB3-4723-B02A-409E7B9EAEBF',
  PASSPORT_COPY: 'E0B7EFD8-19F7-493E-B2C0-B60EF322005B',
  CANADA_ID: '43D24582-C58F-4ACE-9021-275BB6881ADF',
  UNITED_STATES_ID: 'FF81A9AE-8C2E-446B-9DEB-6E8FA622F2EB',
  PROFILE_PHOTO_CATEGORY_ID: '502f93cb-8a92-11ee-8946-b48c9d08af8d',

  // Firebase FCM
  fcmUrl: 'https://fcm.googleapis.com/fcm/send',
  fcmAuthorization: 'key=AAAA0aZCWp4:APA91bFbHBHfjBC_-Bw_r3f_Wg-nxTmyjFgyHKYjPNnRAxjX125jTKz8Exj4qJpnC2A60gYVuvkLqxmHL46WMN28XuOUMIyJ26Z9Hx44GUycun6lBzP8jRehM6bmbhE2fyWE2IJ1kIpb',
  INTERVIEW_SCHEDULE: 'Interview Schedule',

  // COUNTRY
  UNITED_STATES: 'FF81A9AE-8C2E-446B-9DEB-6E8FA622F2EB',
  CANADA: '43D24582-C58F-4ACE-9021-275BB6881ADF',

  MCAT_EXAM: '9E6A65FA-C6EB-4E5B-B413-F83046B1142A',

  VETERINARY_CAMPUS: 'RF30W73A-4DD5-4917-S450-GTB985BF206E',
  ARUBA: { id: '3282D9BA-45BA-46E2-BEAE-3B22C7BBE5D7', name: 'Aruba' },
  ARUB_CELL_PHONE_ALERT_MSG: 'Please add country code along with phone number if it is less than 10 digits',
  ARUBA_REGULAR_EX_SPECIAL_CHAR: /[()\-_\s]/g
};
