/* eslint-disable */
import React from "react"
import {
  NavItem,
  NavLink,
} from "reactstrap"
import { history } from "../../../history"
import { connect } from "react-redux"
import {
  loadSuggestions,
  updateStarred
} from "redux/actions/navbar/Index"
import XusomLogo from "assets/img/logo/xum-logo.png";
import { Link } from "react-router-dom"
import { getAuthUserData } from 'utility/helpers/helperFunction';


class NavbarBookmarks extends React.PureComponent {

  componentDidMount() {
    getAuthUserData()
  }
    render() {
    // let {
    //   bookmarks: { extraStarred }, 
    //   // suggestions
    //   sidebarVisibility,
    //   // updateStarred,
    //   // handleAppOverlay
    // } = this.props
   
    return (
      <div className='mr-auto float-left bookmark-wrapper d-flex align-items-center'>
          <ul className='nav navbar-nav bookmark-icons d-none d-sm-block'>
            <NavItem className='mobile-menu mr-auto'>
              <Link to={`/application/profile/${getAuthUserData().leadId}`}>
              <NavLink  tag="span" className='navbar-brand d-flex p-0'>
                <h4 className='landing-user-name brand-text mb-0' style={{ color: '#7367f0' }}>
                  {/* XUSOM */}
                <img src={XusomLogo} alt="XusomLogo" className="d-block mx-auto xusom-logo" height="50" width="180" />
                </h4>
              </NavLink>
              </Link>
            </NavItem>
          </ul>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    bookmarks: state.navbar
  }
}

export default connect(mapStateToProps, { loadSuggestions, updateStarred })(
  NavbarBookmarks
)
