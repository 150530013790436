export default {
  STATUS: [
    {
      id: 0,
      name: 'Open',

    },
    {
      id: 1,
      name: 'In Progress',

    },
    {
      id: 2,
      name: 'Completed',

    },
    {
      id: 3,
      name: 'Cancelled',

    },
  ]
};
