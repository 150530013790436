export default {
  APPLICANT: {
    PROFILE: {
      icon: 'User',
      hasProfile: true,
      children: [
        {
          id: '20001',
          link: '/application/profile',
          icon: 'User',
          title: 'My Details',
          name: 'profile',
          isUrlParameter: true
        },
        {
          id: '200011',
          link: '/application/background',
          icon: 'Folder',
          title: 'Background',
          name: 'background',
          isUrlParameter: true
        },
        {
          id: '200010',
          link: '/application/academics',
          icon: 'Book',
          title: 'Academic',
          name: 'academic',
          isUrlParameter: true
        },
        {
          id: '200012',
          link: '/application/additional-questions',
          icon: 'FolderPlus',
          title: 'Additional Questions',
          name: 'questions',
          isUrlParameter: true
        },
        {
          id: '2000103',
          link: '/application/submit',
          icon: 'User',
          title: 'Submit',
          name: 'submit',
          isUrlParameter: true
        },
        {
          id: '2000104',
          link: '/application/unofficial-transcripts',
          icon: 'FilePlus',
          title: 'Unofficial Transcripts',
          name: 'unofficial-transcripts',
          isUrlParameter: true
        },
        {
          id: '2000105',
          link: '/application/statement',
          icon: 'FilePlus',
          title: 'Personal Statement',
          name: 'statement',
          isUrlParameter: true
        },
        {
          id: '2000106',
          link: '/application/loa',
          icon: 'FilePlus',
          title: 'Letters of Recommendation',
          name: 'loa',
          isUrlParameter: true
        },
        {
          id: '2000176',
          link: '/application/acceptance-letter',
          icon: 'File',
          title: 'Acceptance Letter',
          name: 'acceptance',
          isUrlParameter: true
        },
        {
          id: '200234177',
          link: '/application/photo',
          icon: 'File',
          title: 'Profile Photo',
          name: 'Profile Photo',
          isUrlParameter: true
        },
        {
          id: '200234176',
          link: '/application/passport',
          icon: 'File',
          title: 'Passport Copy',
          name: 'passport',
          isUrlParameter: true
        },
      ]
    },
  }
};
